<app-header-d> </app-header-d>

<ion-header [translucent]="true" class="mobile">
  <ion-toolbar>
    <ion-title>Blog</ion-title>
    <ion-buttons slot="start">
      <ion-menu-button autoHide="false"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="container-block">
    <ion-grid>
      <ion-row>
        <ion-col>
          <h2 class="page-title">Engenium Solutions Tech Posts</h2>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-md="8">
          <div class="post-card" *ngFor="let post of filteredPosts">
            <div class="post-header">
              <img class="author-image" [src]="post.authorImageUrl" alt="{{ post.author }}" />
              <div>
                <h3 class="post-title" [routerLink]="['/blog/detail', post.id]">{{ post.title }}</h3>
                <p class="post-info">{{ post.created_at.toDate() | date: 'mediumDate' }} by {{ post.author }}</p>
              </div>
            </div>
            <img class="post-image" [src]="post.thumbnailUrl" *ngIf="post.thumbnail" alt="{{ post.title }}" />
            <div class="post-content">
              <h4 class="post-subtitle">{{ post.meta_description }}</h4>
              <p class="post-description">{{ post.summary }}</p>
              <!--<div [innerHTML]="parseMarkdown(post.content)"></div>-->
            </div>
            <ion-button class="continue-reading" [routerLink]="['/blog/detail', post.id]" expand="block">Continue Reading</ion-button>
            <div class="post-tags">
              <ion-badge *ngFor="let category of post.categories" color="primary">
                #{{ category }}
              </ion-badge>
            </div>
          </div>
        </ion-col>
        <ion-col size="12" size-md="4">
          <ion-list-header>
            <ion-label>Filter by Category</ion-label>
          </ion-list-header>
          <ion-list>
            <ion-item button (click)="selectedCategory = ''; filterPostsByCategory()">
              <ion-label>All Categories</ion-label>
            </ion-item>
            <ion-item *ngFor="let category of categories" button (click)="selectedCategory = category; filterPostsByCategory()">
              <ion-label>{{ category }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <app-footer></app-footer>
</ion-content>