<div class="work-with-section">
    <div class="et_pb_row_11 et_pb_row et_pb_with_border">
      <ion-grid class="">
        <ion-row>
          <ion-col class="{{logo.style}} logo_spacer" *ngFor="let logo of logos" size-xs="12" size-sm="6" size-md="4" size-lg="3">
            <a href="{{logo.url}}" class="et_pb_image">
            <ion-img src="{{logo.img}}" alt="{{logo.alt}}"></ion-img>
          </a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
</div>