import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saeg-section',
  templateUrl: './saeg-section.component.html',
  styleUrls: ['./saeg-section.component.scss'],
})
export class SaegSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
