import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-d',
  templateUrl: './header-d.component.html',
  styleUrls: ['./header-d.component.scss'],
})
export class HeaderDComponent implements OnInit {
  public navigate = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'home',
    },
    {
      title: 'Sobre Nosotros',
      url: '/about-us',
      icon: 'home',
    },
    {
      title: 'Servicios',
      url: '/ingenieria',
      icon: 'build',
    },
    {
      title: 'Proyectos',
      url: '/projects',
      icon: 'business',
    },
    /*
    {
      title: 'Novedades',
      url: '/news',
      icon: 'business'
    },
    {
      title: 'Ingreso Clientes',
      url: '/loginClients',
      icon: 'people'
    },
    */
    {
      title: 'Contactanos',
      url: '/contact-us',
      icon: 'location',
    },
    {
      title: 'Blog',
      url: '/blog',
      icon: 'location',
    },
  ];
  constructor(private router: Router) {}

  ngOnInit() {}
  routerP(pages: any) {
    this.router.navigateByUrl(pages);
  }
}
