<ion-footer>
  <div id="container-block-1" class="div-footer">
    <ion-grid>
      <ion-row>
        <ion-col size="3">
          <ion-img src="../assets/img/EngeniumLOGO350x75px.png">
          </ion-img>
        </ion-col>
        <ion-col size-md="6" size-lg="6" size-xl="6" size-xs="9">
          <h3>Engenium Solutions S.A Derechos Reservados</h3>
        </ion-col>
        <ion-col size-md="3" size-lg="3" size-xl="3" size-xs="12">
          <ul class="social">
            <a *ngFor="let media of socialmedia">
              <a href="{{media.url}}">
              <ion-icon
                class="socialIcon"
                size="large"
                name="{{media.icon}}"
              ></ion-icon
            >
          </a></a>
          </ul>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
