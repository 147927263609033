import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogRoutingModule } from './blog-routing.module';
import { DetailPage } from './detail/detail.page';
import { ListPage } from './list/list.page';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components.module';


@NgModule({
  declarations: [ListPage, DetailPage],
  imports: [
    CommonModule,
    IonicModule,
    ComponentsModule,
    FormsModule,
    BlogRoutingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class BlogModule { }
