<div class="who-we-are-section">
  <div class="et_pb_row_11 et_pb_row et_pb_with_border">
<ion-grid>
  <ion-row>
    <ion-col>
      <div>
        <ion-img src="assets/img/floorplan.svg"></ion-img>
      </div>
      <ion-text><h2>INGENIERIA:</h2></ion-text>
      <p>
        Contamos con un equipo especializado de ingenieros en sistemas de automatización y control, nuestro equipo de ingeniería y ventas se encarga de asesorar a nuestros clientes para encontrar la solución que mejor se adapte a las necesidades de los proyectos.</p>
    </ion-col>
    <ion-col>
      <ion-text><h2>Ingeniería y ventas</h2></ion-text>
      <ion-list>
        <ion-item>
          <ion-icon slot='start' name="build"></ion-icon>
          <ion-text>Diseño de Sistemas Automatización & Control.</ion-text>
        </ion-item>
        <ion-item>
          <ion-icon slot='start' name="build"></ion-icon>
          <ion-text>Dimensionamiento de BMS.</ion-text>
          </ion-item>
        <ion-item>
          <ion-icon slot='start' name="build"></ion-icon>
          <ion-text>Selección de controles de instrumentación y supervición.</ion-text>
          </ion-item>
        <ion-item>
          <ion-icon slot='start' name="build"></ion-icon>
          <ion-text>Cotización de Proyecto.</ion-text>
          </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>
</div>
</div>
