<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu side="start" class="mobile" contentId="main-content">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Engenium</ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of navigate">
            <ion-item
              routerDirection="root"
              [routerLink]="[p.url]"
              routerLinkActive="selected"
            >
              <ion-icon
                slot="start"
                [ios]="p.icon + '-outline'"
                [md]="p.icon + '-sharp'"
              ></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
