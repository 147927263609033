import { Injectable } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  private readonly cacheDir: string = 'cache';

  constructor() {
    this.init();
  }

  async init() {
    try {
      await Filesystem.mkdir({
        path: this.cacheDir,
        directory: Directory.Data,
      });
    } catch (e) {
      console.warn('Cache directory already exists.', e);
    }
  }

  async set(key: string, value: any): Promise<void> {
    await Filesystem.writeFile({
      path: `${this.cacheDir}/${key}`,
      data: JSON.stringify(value),
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });
  }

  async get<T = any>(key: string): Promise<T | null> {
    try {
      const { data } = await Filesystem.readFile({
        path: `${this.cacheDir}/${key}`,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      return JSON.parse(data) as T;
    } catch (e) {
      console.warn('Error reading cache file.', e);
      return null;
    }
  }

  async remove(key: string): Promise<void> {
    await Filesystem.deleteFile({
      path: `${this.cacheDir}/${key}`,
      directory: Directory.Data,
    });
  }
}