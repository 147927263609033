<div id="container-block">
  <div class="block">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-text><h1>Nuestros Clientes:</h1></ion-text>
          </ion-col>
          <ion-slides pager="true" [options]="slideOpts" #sliderRef>
            <ion-slide *ngFor="let client of clients" text-center>
              <ion-img src="{{client.img}}" alt="{{client.title}}"></ion-img>
            </ion-slide>
          </ion-slides>
        </ion-row>
      </ion-grid>
  </div>
</div>
