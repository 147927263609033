import { Component, HostListener } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { ScreensizeService } from './services/screensize.service';
import { AnalyticsService } from './services/analytics.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public navigate = [
    {
      title: 'Sobre Nosotros',
      url: '/about-us',
      icon: 'home',
    },
    {
      title: 'Servicios',
      url: '/services',
      icon: 'build',
    },
    {
      title: 'Proyectos',
      url: '/projects',
      icon: 'business',
    },
    {
      title: 'Novedades',
      url: '/news',
      icon: 'business',
    },
    {
      title: 'Ingreso clientes',
      url: '/loginClients',
      icon: 'people',
    },
    {
      title: 'Contactanos',
      url: '/contact-us',
      icon: 'location',
    },
  ];
  constructor(private platform: Platform, private menu: MenuController,private analyticsService: AnalyticsService,) {
    this.initializeApp();
  }
  initializeApp() {
    this.analyticsService.startTrackerWithId('G-GKT7JTWCVL');
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }
}
