import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss'],
})
export class OurClientsComponent implements OnInit {
  @ViewChild('sliderRef', { static: true }) protected slide: IonSlides;
  slideOpts = {
    initialSlide: 0,
    slidesPerView: 4,
    autoplay: true,
    spaceBetween: 3,
  };
  public clients = [
    {
      title: 'Difare',
      img: 'assets/img/clientlogo/difare.png',
    },
    {
      title: 'Asegensa',
      img: 'assets/img/clientlogo/asegensa.png',
    },
    {
      title: 'Pharmacys',
      img: 'assets/img/clientlogo/pharmacys.png',
    },
    {
      title: 'CruzAzul',
      img: 'assets/img/clientlogo/ca.png',
    },
    {
      title: 'Dibiens',
      img: 'assets/img/clientlogo/dibiensLogo.png',
    },
    {
      title: '4PL',
      img: 'assets/img/clientlogo/4pl-logo.png',
    },
    {
      title: 'Clitecser',
      img: 'assets/img/clientlogo/clitecser.jpg',
    },
    {
      title: 'Hotel-Grand-Guayaquil',
      img: 'assets/img/clientlogo/grandGuayaquil.png',
    },
    {
      title: 'Hotel-Ibis-Quito',
      img: 'assets/img/clientlogo/ibisQuito.svg',
    },
    {
      title: 'ELB',
      img: 'assets/img/clientlogo/elbLogo.png',
    },
  ];
  constructor() {}

  ngOnInit() {}
}
