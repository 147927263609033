import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
  lang = [
    {
      language: 'business',
      opt: 'eng',
      icon: 'business'
    },
    {
      language: 'settings',
      opt: 'spa',
      icon: 'settings'
    }
  ];
constructor(
  private popover: PopoverController
) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

ClosePopover(opt: any) {
  this.popover.dismiss(opt);
}
}
