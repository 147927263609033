import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BlogModule } from './blog/blog.module';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'features',
    loadChildren: () =>
      import('./features/features.module').then((m) => m.FeaturesPageModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./policy/policy.module').then((m) => m.PolicyPageModule),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./legal/legal.module').then((m) => m.LegalPageModule),
  },
  {
    path: 'become-partnet',
    loadChildren: () =>
      import('./become-partnet/become-partnet.module').then(
        (m) => m.BecomePartnetPageModule
      ),
  },
  {
    path: 'smart-buildings',
    loadChildren: () =>
      import('./smart-buildings/smart-buildings.module').then(
        (m) => m.SmartBuildingsPageModule
      ),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./careers/careers.module').then((m) => m.CareersPageModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsPageModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./services/services.module').then((m) => m.ServicesPageModule),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./news/news.module').then((m) => m.NewsPageModule),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then((m) => m.ProjectsPageModule),
  },
  { 
    path: 'project-details/:id',
    loadChildren: () =>
    import('./projects/project-details/project-details.module').then(m => m.ProjectDetailsPageModule) },
  {
    path: 'ingenieria',
    loadChildren: () =>
      import('./services/ingenieria/ingenieria.module').then(
        (m) => m.IngenieriaPageModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => BlogModule),
  },
  {
    path: 'alexa',
    loadChildren: () => import('./alexa/alexa.module').then( m => m.AlexaPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
   })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
