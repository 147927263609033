import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { getFirestore, Timestamp } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { CachingService } from './caching.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';



interface Post {
  id: string;
  title: string;
  content: string;
  author: string;
  author_image_url?: string;
  slug: string;
  created_at: Timestamp;
  updated_at: Timestamp;
  categories: string[];
  thumbnail?: string;
  is_published?: boolean;
  meta_description?: string;
  reading_time?: number;
  summary: string;
}

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private firestore: AngularFirestore, private cachingService: CachingService, private storage: AngularFireStorage) {}

  getPostList(): Observable<Post[]> {
    return this.firestore
      .collection('posts')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Omit<Post, 'id'>;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getPost(postId: string) {
    return this.firestore.doc(`posts/${postId}`).valueChanges();
  }


  async fetchAndCacheImage(imagePath: string): Promise<string> {
    const cachedImage = await this.cachingService.get<string>(imagePath);
    if (cachedImage) {
      return cachedImage;
    } else {
      const downloadUrl = await this.storage.ref(imagePath).getDownloadURL().toPromise();
      await this.cachingService.set(imagePath, downloadUrl);
      return downloadUrl;
    }
  }

  fetchCachedAuthorImage(authorId: string): Promise<string> {
    const imagePath = `author_images/${authorId}.jpg`;
    return this.fetchAndCacheImage(imagePath);
  }
  
  fetchCachedThumbnailImage(postId: string): Promise<string> {
    const imagePath = `thumbnail_images/${postId}.jpg`;
    return this.fetchAndCacheImage(imagePath);
  }

  fetchCachedPostMd(contentURL: string): Promise<string> {
    const MdPath = `markdown/${contentURL}.md`;
    return this.fetchAndCacheImage(MdPath);
  }
}