import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../blog.service';
import { marked } from 'marked';
@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit {
  posts: any[] = [];
  filteredPosts: any[] = [];
  categories: string[] = [];
  selectedCategory: string = '';

  constructor(private blogService: BlogService) {}

  ngOnInit() {
    this.blogService.getPostList().subscribe((data: any[]) => {
      this.posts = data;
      this.filteredPosts = data;
      this.categories = this.extractCategories(data);

    // Fetch the author and thumbnail images after getting the posts
    this.fetchImages();
    });
  }

  filterPostsByCategory() {
    if (this.selectedCategory === '') {
      this.filteredPosts = this.posts;
    } else {
      this.filteredPosts = this.posts.filter((post) =>
        post.categories.includes(this.selectedCategory)
      );
    }
      // Update the selected class
  const items = Array.from(document.querySelectorAll('.filter-item'));
  items.forEach((item) => {
    item.classList.remove('selected');
  });
  const selectedItem = document.querySelector(`[data-category="${this.selectedCategory}"]`);
  if (selectedItem) {
    selectedItem.classList.add('selected');
  }
  }

  private extractCategories(posts: any[]): string[] {
    const allCategories = posts.reduce((acc, post) => {
      return acc.concat(post.categories);
    }, []);
    return Array.from(new Set(allCategories));
  }

  async fetchImages() {
    const fetchImagePromises = this.filteredPosts.map(async (post) => {
      if (post.author && post.author_image_url) {
        const authorImageUrl = await this.blogService.fetchCachedAuthorImage(post.author_image_url);
        post.authorImageUrl = authorImageUrl;
      }
     if (post.thumbnail ) {
        const thumbnailUrl = await this.blogService.fetchCachedThumbnailImage(post.thumbnail);
        post.thumbnailUrl = thumbnailUrl;
      }
    });
  
    await Promise.all(fetchImagePromises);
  }

  parseMarkdown(content: string): string {
    return marked(content);
  }
}