import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-with-products-v2',
  templateUrl: './work-with-products-v2.component.html',
  styleUrls: ['./work-with-products-v2.component.scss'],
})
export class WorkWithProductsV2Component implements OnInit {
  logos = [
    {
      img: 'assets/3rdPartyLogo/orbitLogo.png',
      url: 'https://orbit.saeg.com',
      alt: 'saeg-orbit-logo',
      style: 'logo_image_1',
    },
    {
      img: 'assets/3rdPartyLogo/siemensLogo.svg',
      url: 'https://www.siemens.com',
      alt: 'siemens-logo',
      style: 'logo_image_3',
    },
    {
      img: 'assets/3rdPartyLogo/tridium-logo.svg',
      url: 'https://www.tridium.com',
      alt: 'tridium-logo',
      style: 'logo_image_1',
    },
    {
      img: 'assets/3rdPartyLogo/distech-controls-logo.svg',
      url: 'https://www.distech-controls.com',
      alt: 'distech-logo',
      style: 'logo_image_4',
    },
    {
      img: 'assets/3rdPartyLogo/unipi-logo-short.svg',
      url: 'https://www.unipi.technology',
      alt: 'unipi-logo',
      style: 'logo_image_1',
    },
    {
      img: 'assets/3rdPartyLogo/daikin-logo.svg',
      url: 'https://www.daikin.com',
      alt: 'daikin-logo',
      style: 'logo_image_7',
    },
    {
      img: 'assets/3rdPartyLogo/saeg-logo.svg',
      url: 'https://www.saeg.com',
      alt: 'saeg-logo',
      style: 'logo_image_1',
    },
    {
      img: 'assets/3rdPartyLogo/greenheck-logo.png',
      url: 'https://www.greenheck.com',
      alt: 'greenheck-logo',
      style: 'logo_image_1',
    },
  ];
  constructor() {}

  ngOnInit() {}
}
