import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-know-more-home-page',
  templateUrl: './know-more-home-page.component.html',
  styleUrls: ['./know-more-home-page.component.scss'],
})
export class KnowMoreHomePageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}
  routerPageChange(pages:any) {
    this.router.navigateByUrl(pages);
}
}
