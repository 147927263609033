<div id="container-block-2">
  <div class="block3">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h2>Quieres saber más sobre nosotros?</h2>
            <p>
              Puedes escribirnos o visitarnos a nuestro local para conocer más
              acerca de nosotros
            </p>
            <ion-button
              (click)="routerPageChange('/contact-us')"
              shape="round"
              mode="ios"
              color="dark"
              >Contáctanos</ion-button
            >
            <ion-button
              (click)="routerPageChange('/services')"
              shape="round"
              mode="ios"
              color="light"
              >Servicios</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>