// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBec_aKScKegRCKZgimTJlPlsAk3QuIDQE",
    authDomain: "chrome-coast-188816.firebaseapp.com",
    databaseURL: "https://chrome-coast-188816.firebaseio.com",
    projectId: "chrome-coast-188816",
    storageBucket: "chrome-coast-188816.appspot.com",
    messagingSenderId: "297290986502",
    appId: "1:297290986502:web:0be4710659f587f6eb7f87",
    measurementId: "G-GKT7JTWCVL"
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
