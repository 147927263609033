import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-image-div-pages',
  templateUrl: './first-image-div-pages.component.html',
  styleUrls: ['./first-image-div-pages.component.scss'],
})
export class FirstImageDivPagesComponent implements OnInit {
  @Input() title: string;
  @Input() image: string;
  constructor() { }

  ngOnInit() {}

}
