import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
public socialmedia = [
  {
    icon : 'logo-facebook',
    url: 'www.facebook.com'
  },
  {
    icon : 'logo-twitter',
    url: 'www.twitter.com'
  },
  {
    icon : 'logo-instagram',
    url: 'www.instagram.com'
  },
  {
    icon : 'logo-linkedin',
    url: 'www.linkedin.com'
  },
]
  constructor() { }

  ngOnInit() {}

}
