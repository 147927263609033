import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-with-products',
  templateUrl: './work-with-products.component.html',
  styleUrls: ['./work-with-products.component.scss'],
})
export class WorkWithProductsComponent implements OnInit {
  logos = [
    {
      img: 'assets/3rdPartyLogo/distech.png',
    },
    {
      img: 'assets/3rdPartyLogo/sonos.png',
    },
    {
      img: 'assets/3rdPartyLogo/nest.png',
    },
    {
      img: 'assets/3rdPartyLogo/alhua.png',
    },
    {
      img: 'assets/3rdPartyLogo/amazon.jpg',
    },
    {
      img: 'assets/3rdPartyLogo/hikvision.png',
    },
    {
      img: 'assets/3rdPartyLogo/lutron.png',
    },
    {
      img: 'assets/3rdPartyLogo/unipi.png',
    },
    {
      img: 'assets/3rdPartyLogo/eero.png',
    },
    {
      img: 'assets/3rdPartyLogo/orbitLogo.png',
    },
    {
      img: 'assets/3rdPartyLogo/N4Logo.png',
    },
    {
      img: 'assets/3rdPartyLogo/siemensLogo.svg',
    },
  ];
  constructor() {}

  ngOnInit() {}
}
