<ion-header [translucent]="true" class="desktop" overlay="true">
  <ion-toolbar>
    <a slot="start" href="https://www.engeniumsolutions.com">
      <ion-img
        src="../assets/img/EngeniumLOGO350x75px.png"
      ></ion-img
    ></a>
    <ion-buttons
      style="padding: 0px 5px"
      collapse="true"
      slot="end"
      *ngFor="let pages of navigate"
    >
      <ion-button (click)="routerP(pages.url)">{{ pages.title }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
