import { OurClientsComponent } from './our-clients/our-clients.component';
import { FirstImageDivPagesComponent } from './first-image-div-pages/first-image-div-pages.component';
import { WorkWithProductsComponent } from './work-with-products/work-with-products.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LangComponent } from './popovers/lang/lang.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderDComponent } from './header-d/header-d.component';
import { KnowMoreHomePageComponent } from './know-more-home-page/know-more-home-page.component';
import { SaegSectionComponent } from './saeg-section/saeg-section.component';
import { WorkWithProductsV2Component } from './work-with-products-v2/work-with-products-v2.component';
import { WhoWeAreComponent } from './about-us/who-we-are/who-we-are.component';
import { ServiceFirstSectionComponent } from './services/service-first-section/service-first-section.component';
@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [
    LangComponent,
    FooterComponent,
    HeaderDComponent,
    KnowMoreHomePageComponent,
    WorkWithProductsComponent,
    FirstImageDivPagesComponent,
    OurClientsComponent,
    SaegSectionComponent,
    WorkWithProductsV2Component,
    WhoWeAreComponent,
    ServiceFirstSectionComponent,
  ],
  exports: [
    LangComponent,
    FooterComponent,
    HeaderDComponent,
    KnowMoreHomePageComponent,
    WorkWithProductsComponent,
    FirstImageDivPagesComponent,
    OurClientsComponent,
    SaegSectionComponent,
    WorkWithProductsV2Component,
    WhoWeAreComponent,
    ServiceFirstSectionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
