<div id="container-block">
  <div class="block">
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-text><h1>Trabajamos con:</h1></ion-text>
          </ion-col>
          <ion-col style="padding-top: 8%;" *ngFor="let logo of logos" size-xs="12" size-sm="6" size-md="4" size-lg="3">
            <ion-img src="{{logo.img}}"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>